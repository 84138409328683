import React, { Component } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Home from 'pages/Home'
import Footer from 'components/Footer'

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
          <Switch>
            <Route exact path={`/`} component={Home} />
            <Redirect to="/" />
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    )
  }
}

export default Router