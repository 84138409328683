import React, { Component } from 'react';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import strings from 'data/strings';
import api from 'common/api';

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      validated: false,
      emailAddress: '',
      subscribeInProgress: false,
      subscribeSucceeded: false,
      subscribeFailed: false
    }

    this.onSubmitSubscribe = this.onSubmitSubscribe.bind(this);
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  async onSubmitSubscribe(event) {
    event.preventDefault();

    const form = event.currentTarget;
    let isValid = form.checkValidity();
    event.stopPropagation();
    this.setState({ validated: true });

    if (!isValid) {
      return;
    }
    this.setState({ subscribeInProgress: true })
    var success = await api.callSubscribe('Supplementics', this.state.emailAddress);
    this.setState({ subscribeInProgress: false })

    if (success) {
      this.setState({ subscribeSucceeded: true })
    }
    else {
      this.setState({ subscribeFailed: true })
    }
  }

  render() {
    return (
      <>
      <header>
      </header>
      <main className="mt-auto px-3">
        <h1 className="pb-4">Supplementics</h1>
        <p className="lead muted">Supplementics is in stealth mode! Sign up below to receive updates on our launch.</p>
        <Row className="input-group">
          <Col md="12" className="justify-content-center">
          {this.state.subscribeInProgress && (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Subscribing</span>
            </div>
          )}
          {this.state.subscribeFailed && (
            <p className='text-danger'>{strings.subscribe.subscribeFailed}</p>
          )}
          {this.state.subscribeSucceeded && (
            <p className='text-success'>{strings.subscribe.subscribeSucceeded}</p>
          )}
          {!this.state.subscribeSucceeded && !this.state.subscribeInProgress && (
            <Form noValidate validated={this.state.validated} onSubmit={this.onSubmitSubscribe}>
              <Form.Group controlId="validationCustom01">
                <Form.Control required type="email" name="emailAddress" placeholder="Your email address" onChange={(event) => this.handleUserInput(event)} />
                <p><small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small></p>
                <button type="submit" class="btn btn-primary">Submit</button>
              </Form.Group>
            </Form>
          )}
          </Col>
        </Row>
      </main>
      </>
    );
  }
}

export default Home;
